import React from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import Linker from 'app/shared/modules/Linker';
import { yieldCallback } from '@zillow/yield-callback';
import 'app/shared/modules/navigation/header/HeaderNavMenuItem.scss';

interface HeaderNavMenuItemProps {
    active?: boolean;
    escape?: boolean;
    mainLinkUrl?: string;
    children: React.ReactNode;
    onClick?: () => void;
}

const HeaderNavMenuItem: React.FC<HeaderNavMenuItemProps> = ({
    active,
    escape,
    mainLinkUrl,
    children,
    onClick
}) => {
    const dispatch = useDispatch();

    const yieldTransitionTo = yieldCallback((url: string) => {
        window.router.transitionTo(url);
    });

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (onClick) {
            onClick();
            return;
        }
        e.preventDefault();
        dispatch(
            analyticsEvent(gaEvents.NAV_BAR_CLICK, {
                action: children,
                label: children
            })
        );
        if (mainLinkUrl) {
            yieldTransitionTo(mainLinkUrl);
        }
    };

    const linkerCss = cx({
        'HeaderNavMenuItem-item': true,
        active
    });

    const querylessUriMain = (mainLinkUrl || '').split('?')[0];

    return (
        <li className="HeaderNavMenuItem">
            <Linker
                disabled={active}
                className={linkerCss}
                escape={escape}
                to={querylessUriMain}
                onClick={handleClick}
                linkType="inherit"
            >
                {children}
            </Linker>
        </li>
    );
};

export default HeaderNavMenuItem;