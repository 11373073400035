import React from 'react';
import loadable from '@loadable/component';
import { ComponentType } from 'react';

interface HamburgerNavComponentProps {
    onCloseHamburgerNav: () => void;
}

const HamburgerNav = loadable(
    () =>
        import(
            /* webpackChunkName: "HamburgerNav.loadable" */ 'app/shared/modules/navigation/header/HamburgerNavComponent'
        )
);

const LoadableHamburgerNav = (props: HamburgerNavComponentProps) => {
    return <HamburgerNav {...props} />;
}

export default LoadableHamburgerNav;