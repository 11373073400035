// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { yieldCallback } from '@zillow/yield-callback';

import * as S from 'app/shared/modules/LoginStatus/styles';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import IconUserProfile from 'images/icons/user-profile-green.svg';
import IconUserProfileBlue from 'images/icons/user-profile-blue.svg';
import Linker from 'app/shared/modules/Linker';
import pathsManifest from 'app/shared/utils/pathsManifest';
import { TrackHeaderLogin } from 'app/shared/models/Clickstream/LoginClickstreamEvents';

class LoginStatus extends Component {
    static propTypes = {
        loggedIn: PropTypes.bool,
        isUserAccountPage: PropTypes.bool,
        userInfo: PropTypes.object
    };

    static defaultProps = {
        loggedIn: false,
        isUserAccountPage: false,
        userInfo: {
            email: null,
            firstName: null,
            lastName: null,
            roles: []
        }
    };

    constructor(props) {
        super(props);
    }

    yieldTransitionToUserDashboardPage = yieldCallback(() => {
        window.router.transitionTo(pathsManifest.UserDashboardPage);
    });

    yieldTransitionToSignInPage = yieldCallback((link) => {
        this.props.dispatch(analyticsEvent(gaEvents.HEADER_SIGN_IN, { newLaneEvent: TrackHeaderLogin() }));

        window.router.transitionTo(link);
    });

    yieldTransitionToListYourRentalPage = yieldCallback(() => {
        this.props.dispatch(
            analyticsEvent(gaEvents.LIST_OR_MANAGE_RENTALS, { action: 'listYourRental' })
        );
        window.router.transitionTo(pathsManifest.PostAListingPage);
    });

    handleListYourRentalClick = (e) => {
        // Prevent the browser from going through with its default <a> behavior
        e.preventDefault();

        this.yieldTransitionToListYourRentalPage();
    };

    handleUsernameClick = (e) => {
        // Prevent the browser from going through with its default <a> behavior
        e.preventDefault();

        this.yieldTransitionToUserDashboardPage();
    };

    handleSignInClick = (e, link) => {
        // Prevent the browser from going through with its default <a> behavior
        e.preventDefault();

        this.yieldTransitionToSignInPage(link);
    };

    contentLoggedOut = () => {
        const { pathname } = this.props;
        const link = `${pathsManifest.LoginPage}?redirect=${pathname}`;
        const isLoginPage = pathname === '/login';

        return (
            <S.LoginStatusGroup>
                <Linker
                    disabled={isLoginPage}
                    aria-disabled={isLoginPage.toString()}
                    tabIndex={isLoginPage ? -1 : 0}
                    linkType={isLoginPage ? 'accent-light' : 'default'}
                    onClick={(e) => this.handleSignInClick(e, link)}
                    to={link}
                >
                    Sign in / Register
                </Linker>
            </S.LoginStatusGroup>
        );
    };

    contentLoggedIn = () => {
        const { userInfo, isUserAccountPage } = this.props;

        return (
            <S.LoginStatusGroup>
                <Linker
                    onClick={this.handleUsernameClick}
                    to={pathsManifest.UserDashboardPage}
                    linkType="text-color"
                >
                    <S.LoginStatusProfileIcon
                        src={isUserAccountPage ? IconUserProfileBlue : IconUserProfile}
                        className="LoginStatus-profile-icon"
                    />
                    {userInfo.firstName ? userInfo.firstName : userInfo.email}
                </Linker>
            </S.LoginStatusGroup>
        );
    };

    render = () => {
        const { loggedIn } = this.props;
        const postAListngPage = pathsManifest.PostAListingPage;

        return (
            <S.LoginStatus>
                <S.LoginStatusProperties>
                    <Linker
                        to={postAListngPage}
                        onClick={this.handleListYourRentalClick}
                    >
                        List your rental
                    </Linker>
                </S.LoginStatusProperties>
                <S.LoginStatusLink>
                    {loggedIn ? this.contentLoggedIn() : this.contentLoggedOut()}
                </S.LoginStatusLink>
            </S.LoginStatus>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.user.info,
        loggedIn: state.user.loggedIn,
        pathname: state.location.current.pathname
    };
};

export default connect(mapStateToProps)(LoginStatus);
