import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { areaUtils_getBestArea } from 'app/shared/utils/areaUtils';
import { filterUtils_getPropertyForRent } from 'app/shared/utils/filterUtils';
import HamburgerNav from 'app/shared/modules/navigation/header/HamburgerNav';
import ConversationBadge from 'app/shared/modules/ConversationBadge';
import HeaderNavMenuItem from 'app/shared/modules/navigation/header/HeaderNavMenuItem';
import hotpadsLogoWithText from 'images/hotpads-logo-with-text.min.svg';
import Linker from 'app/shared/modules/Linker';
import LoginStatus from 'app/shared/modules/LoginStatus';
import pathsManifest from 'app/shared/utils/pathsManifest';
import routeUtils from 'app/shared/utils/routeUtils';
import VisuallyHidden from 'app/shared/modules/VisuallyHidden';
import 'app/shared/modules/navigation/header/HeaderNavMdAndUp.scss';

interface HeaderNavMdAndUpProps {
    onOpenHamburgerNav: () => void;
    onCloseHamburgerNav: () => void;
    unreadMsgCount: number;
}

interface RootState {
    app: {
        hamburger: boolean;
    };
    filter: {
        search: {
            slug: string;
        };
    };
    user: {
        unreadMsgCount: number;
    };
    listings: {
        listingGroups: {
            inquired: any;
        };
    };
}

const HeaderNavMdAndUp: React.FC<HeaderNavMdAndUpProps> = ({
    onOpenHamburgerNav,
    onCloseHamburgerNav,
    unreadMsgCount
}) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { area, hamburgerState, searchSlug } = useSelector((state: RootState) => ({
        area: areaUtils_getBestArea(state),
        hamburgerState: state.app.hamburger,
        searchSlug: state.filter.search.slug
    }));

    const pathname = location.pathname;
    const isAreaPage = routeUtils.isAreaUrl(pathname);
    const { apartmentsForRent, housesForRent } = filterUtils_getPropertyForRent(area);

    const handleMessageLinkClick = () => {
        dispatch(analyticsEvent(gaEvents.SATELLITE_NAV_LINK_CLICK));
    };

    return (
        <div className="HeaderNavMdAndUp">
            <header>
                <nav className="HeaderNavMdAndUp-header-nav" aria-label="main">
                    <button
                        className="HeaderNavMdAndUp-menu-icon"
                        onClick={onOpenHamburgerNav}
                        aria-label="HotPads navigation menu"
                        aria-expanded={hamburgerState}
                    >
                        <VisuallyHidden>Navigation menu</VisuallyHidden>
                        <span />
                    </button>
                    <HamburgerNav onCloseHamburgerNav={onCloseHamburgerNav} />
                    <div className="HeaderNavMdAndUp-logo-wrapper">
                        <Linker to={pathsManifest.FrontPage}>
                            <img
                                src={hotpadsLogoWithText}
                                className="HeaderNavMdAndUp-logo"
                                alt="HotPads"
                                // @ts-ignore
                                // eslint-disable-next-line react/no-unknown-property
                                fetchPriority="low"
                                decoding="async"
                            />
                        </Linker>
                    </div>
                    <ul className="HeaderNavMdAndUp-nav-list">
                        <HeaderNavMenuItem
                            active={isAreaPage && searchSlug === 'apartments-for-rent'}
                            mainLinkUrl={apartmentsForRent}
                        >
                            Apartments
                        </HeaderNavMenuItem>
                        <HeaderNavMenuItem
                            active={isAreaPage && searchSlug === 'houses-for-rent'}
                            mainLinkUrl={housesForRent}
                        >
                            Houses
                        </HeaderNavMenuItem>
                        <HeaderNavMenuItem
                            active={pathname === '/user/listings/my-hub'}
                            mainLinkUrl={pathsManifest.MyHubPage}
                        >
                            My Hub
                        </HeaderNavMenuItem>
                        <HeaderNavMenuItem
                            mainLinkUrl={pathsManifest.InboxPage}
                            onClick={handleMessageLinkClick}
                            escape
                        >
                            Messages
                            {unreadMsgCount > 0 && <ConversationBadge>{unreadMsgCount}</ConversationBadge>}
                        </HeaderNavMenuItem>
                        <HeaderNavMenuItem
                            active={pathname === '/user/saved-search'}
                            mainLinkUrl={pathsManifest.SavedSearchPage}
                        >
                            Alerts
                        </HeaderNavMenuItem>
                    </ul>
                    <LoginStatus />
                </nav>
            </header>
        </div>
    );
};

export default HeaderNavMdAndUp;